



































































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import { ApiResponse, RequisitionItem } from '@/interfaces'
import { stockStatuses } from '@/formHelpers'
import { mapState } from 'vuex'

export default Vue.extend({
  components: {
    Nav,
  },
  data() {
    return {
      busy: false,
      search: '',
      results: [],
      noresults: false,
      dashboardMessage: null,
      dataSent: '0',
      dataQuota: '0',
      dataProgress: 0,
      dataProgressColor: '#7f7fa6',
      emailSent: '0',
      emailQuota: '0',
      emailProgress: 0,
      emailProgressColor: '#7f7fa6',
      yousignSent: '0',
      yousignQuota: '0',
      yousignProgress: 0,
      yousignProgressColor: '#7f7fa6',
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      this.dashboardMessage = newVal.dashboardMessage ?? null

      this.dataSent = newVal.client?.diskUsage
        ? Intl.NumberFormat('fr', { notation: 'compact' }).format(parseInt(newVal.client.diskUsage) / 1000000)
        : '0'
      this.dataQuota = newVal.client?.diskQuota
        ? Intl.NumberFormat('fr', { notation: 'compact' }).format(parseInt(newVal.client.diskQuota) / 1000000)
        : 'N/A'
      this.emailSent = newVal.client?.emailSent ? Intl.NumberFormat('fr').format(newVal.client.emailSent) : '0'
      this.emailQuota = newVal.client?.emailQuota ? Intl.NumberFormat('fr').format(newVal.client.emailQuota) : 'N/A'
      this.yousignSent = newVal.client?.yousignSent ? Intl.NumberFormat('fr').format(newVal.client.yousignSent) : '0'
      this.yousignQuota = newVal.client?.yousignQuota
        ? Intl.NumberFormat('fr').format(newVal.client.yousignQuota)
        : 'N/A'

      // Data progress
      if (newVal.client?.diskUsage !== null || newVal.client?.diskQuota == null) {
        if (parseInt(newVal.client.diskUsage) >= newVal.client.diskQuota) {
          this.dataProgress = 100
        } else {
          this.dataProgress = (parseInt(newVal.client.diskUsage) / parseInt(newVal.client.diskQuota)) * 100
        }
      } else {
        this.dataProgress = 0
      }

      // Email progress
      if (newVal.client?.emailSent !== null || newVal.client?.emailQuota == null) {
        if (parseInt(newVal.client.emailSent) >= newVal.client.emailQuota) {
          this.emailProgress = 100
        } else {
          this.emailProgress = (parseInt(newVal.client.emailSent) / parseInt(newVal.client.emailQuota)) * 100
        }
      } else {
        this.emailProgress = 0
      }

      // Signature progress
      if (newVal.client?.yousignSent !== null || newVal.client?.yousignQuota == null) {
        if (parseInt(newVal.client.yousignSent) >= newVal.client.yousignQuota) {
          this.yousignProgress = 100
        } else {
          this.yousignProgress = (parseInt(newVal.client.yousignSent) / parseInt(newVal.client.yousignQuota)) * 100
        }
      } else {
        this.yousignProgress = 0
      }

      // Defining progress bars color
      this.dataProgressColor = this.dataProgress > 80 ? '#f7ad57' : '#7f7fa6'
      this.emailProgressColor = this.emailProgress > 80 ? '#f7ad57' : '#7f7fa6'
      this.yousignProgressColor = this.yousignProgress > 80 ? '#f7ad57' : '#7f7fa6'
    },
  },
  mounted() {
    if (this.user) {
      this.dashboardMessage = this.user.dashboardMessage ?? null

      this.dataSent = this.user.client?.diskUsage
        ? Intl.NumberFormat('fr', { notation: 'compact' }).format(parseInt(this.user.client.diskUsage) / 1000000)
        : '0'
      this.dataQuota = this.user.client?.diskQuota
        ? Intl.NumberFormat('fr', { notation: 'compact' }).format(parseInt(this.user.client.diskQuota) / 1000000)
        : 'N/A'
      this.emailSent = this.user.client?.emailSent ? Intl.NumberFormat('fr').format(this.user.client.emailSent) : '0'
      this.emailQuota = this.user.client?.emailQuota
        ? Intl.NumberFormat('fr').format(this.user.client.emailQuota)
        : 'N/A'
      this.yousignSent = this.user.client?.yousignSent
        ? Intl.NumberFormat('fr').format(this.user.client.yousignSent)
        : '0'
      this.yousignQuota = this.user.client?.yousignQuota
        ? Intl.NumberFormat('fr').format(this.user.client.yousignQuota)
        : 'N/A'

      // Data progress
      if (this.user?.client?.diskUsage !== null || this.user?.client?.diskQuota == null) {
        if (parseInt(this.user.client.diskUsage) >= this.user.client.diskQuota) {
          this.dataProgress = 100
        } else {
          this.dataProgress = (parseInt(this.user?.client?.diskUsage) / parseInt(this.user?.client?.diskQuota)) * 100
        }
      } else {
        this.dataProgress = 0
      }

      // Email progress
      if (this.user?.client?.emailSent !== null || this.user?.client?.emailQuota == null) {
        if (parseInt(this.user?.client?.emailSent ?? 0) >= this.user?.client?.emailQuota ?? 0) {
          this.emailProgress = 100
        } else {
          this.emailProgress =
            (parseInt(this.user?.client?.emailSent) / parseInt(this.user?.client?.emailQuota)) * 100
        }
      } else {
        this.emailProgress = 0
      }

      // Signature progress
      if (this.user?.client?.yousignSent !== null || this.user?.client?.yousignQuota == null) {
        if (parseInt(this.user?.client?.yousignSent) >= this.user?.client?.yousignQuota ?? 0) {
          this.yousignProgress = 100
        } else {
          this.yousignProgress =
            (parseInt(this.user?.client?.yousignSent) / parseInt(this.user?.client?.yousignQuota)) * 100
        }
      } else {
        this.yousignProgress = 0
      }

      // Defining progress bars color
      this.dataProgressColor = this.dataProgress > 80 ? '#f7ad57' : '#7f7fa6'
      this.emailProgressColor = this.emailProgress > 80 ? '#f7ad57' : '#7f7fa6'
      this.yousignProgressColor = this.yousignProgress > 80 ? '#f7ad57' : '#7f7fa6'
    }
  },
  methods: {
    submit() {
      if (this.search.length > 0) {
        this.results = []
        this.noresults = false
        this.busy = true
        this.$api
          .post(`/quicksearch`, { search: this.search })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            if (apiResponse.data.length == 0) {
              this.noresults = true
            }

            if (apiResponse.data.length == 1) {
              this.goToItem(apiResponse.data[0])
            } else {
              this.results = apiResponse.data
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    getStockStatus(status?: string) {
      let str = '-'

      stockStatuses.forEach((stockStatus) => {
        if (stockStatus.value == status) {
          str = stockStatus.label
        }
      })

      return str
    },
    goToItem(item: RequisitionItem) {
      this.$router.push({
        name: 'requisition_item_edit',
        params: {
          itemId: item.id?.toString() ?? '',
        },
      })
    },
    getItemDescription(desc: string) {
      if (desc.length > 60) {
        return desc.substring(0, 60) + '...'
      } else {
        return desc
      }
    },
    trimDesc(desc: string) {
      return desc.length > 80 ? desc.slice(0, 80) + '...' : desc
    },
  },
})
